/* Montserrat Bold */
@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
}

/* Montserrat Medium */
@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-Medium.ttf') format('truetype');
    font-weight: normal; /* Medium weight font, so font-weight is normal */
}

/* Montserrat Regular */
@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-Regular.ttf') format('truetype');
    font-weight: normal; /* Regular weight font, so font-weight is normal */
}

/* Montserrat SemiBold */
@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-SemiBold.ttf') format('truetype');
    font-weight: bold; /* SemiBold font, so font-weight is bold */
}
