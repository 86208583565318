@import "./fonts/fonts.css";

body {
    padding: 0;
    padding-top: 50px !important;
    margin: 0;
    background-color: #eceff3;
    list-style: none;
    overflow-x: hidden;
    font-family: 'Montserrat', sans-serif;
}

* {
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
}

p {
    width: fit-content;
}

input {
    outline: none !important;
    font-weight: 500;
}


.dashboard-item {
    margin: 1px;
    border-radius: 8px;
    background: #f6f7f9;
    padding: 0 10px;
    position: relative;
}


.react-grid-item {
    background-color: white;
    border: 1px solid rgba(236, 239, 243, 0.458824);
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.08);
}

.react-grid-item {
    transition: border 0.1s ease;
}

.react-grid-item:hover {
    transition: border 0.1s ease;
    border: 1px dashed #c0c0c0;
}

.react-resizable-handle:after {
    display: none;
}

.react-resizable-handle {
    display: block;
    width: 0;
    height: 0;
    opacity: 0;
    background: white;
    border: 1px solid #e8e8e8;
    transform: none !important;
    box-shadow: 0 0 10px 0 #e8e8e8;
    transition: 0.1s ease;
}

.react-grid-item:hover > .react-resizable-handle {
    width: 14px;
    height: 14px;
    opacity: 1;

}

.react-resizable-handle-ne {
    top: -7px !important;
    right: -7px !important;
}

.react-resizable-handle-e {
    right: -7px !important;
}

.react-resizable-handle-se {
    bottom: -7px !important;
    right: -7px !important;
}


.react-resizable-handle-s {
    bottom: -7px !important;
}

.react-resizable-handle-sw {

    bottom: -7px !important;
    left: -7px !important;
}

.react-resizable-handle-w {
    left: -7px !important;
}

.react-resizable-handle-n {
    top: -7px !important;
}

.react-resizable-handle-nw {
    top: -7px !important;
    left: -7px !important;

}

.widget-container-fixed {
    position: relative;
}

.dashboard-background {
    padding: 10px;
    position: absolute;
    left: 0;
    width: 99%;
    height: 100%;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: 100px;
    grid-auto-flow: dense;
}

.dashboard-item-content {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 25px;
}

.react-grid-placeholder {
    display: none;
}

.ant-select-dropdown {
    padding: 0;
}

.ant-select-item-option-selected {
    font-weight: 500 !important;
    background: #F8F9FA !important;
}

.ant-select-selector, .ant-select-single, .ant-select-item-option {
    min-height: 45px !important;
}

.ant-select-selector {
    overflow-y: scroll;
    padding-left: 5px !important;
}

.ant-select-dropdown, .ant-select-selector {
    opacity: 1 !important;
    border-radius: 8px !important;
    border: 1px solid rgb(177, 186, 202) !important;
}

.ant-select-item-option-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.ant-modal {
    margin: 0 !important;
    padding: 0 !important;
}

.ant-btn-primary:disabled {
    color: white !important;
}

.ant-table-cell {
    padding: 5px !important;
}

.ant-table-tbody > *:nth-child(even) {
    background: #FAFAFA;

}

.ant-picker-outlined {
    box-shadow: none !important;
    border-color: #d9d9d9 !important;
    outline: none !important;
}

.ant-picker-active-bar {
    background: #5869f0 !important;
}

.ant-picker-input {
    outline: none;
}

.filters-popover {
    width: 100%;
    padding: 0 20px;
    margin: 0 20px;
}

.ant-modal-close {
    display: none;
}

.ant-modal-footer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}
